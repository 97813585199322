import responsive from "../../themes/Responsive";
import { states } from "../selections";

const requestFormTemplate = {
  eventInformation: {
    label: "Additional Information",
    fields: [
      {
        name: "event_amount_request",
        type: "text",
        placeholder: "Monetary Amount Requesting*",
        responsive: responsive.halfFormWidth
      },
      {
        name: "event_purpose_contribution_request",
        type: "text",
        placeholder: "Purpose contribution Request*",
        responsive: responsive.halfFormWidth
      },
      {
        name: "event_description_of_request",
        component: "textarea",
        row: 5,
        placeholder: "Please provide a brief description or synopsis of your request. The Ryman Hospitality Properties Foundation is particularly interested in supporting organizations that help address the opportunity gaps that exist for underrepresented members of our communities. Please share any information on your organization’s commitment to diversity and inclusion that will help us assess how your organization’s mission supports underrepresented groups.*",
        responsive: responsive.fullFormWidth
      },
      {
        name: "is_event_supported_before",
        type: "select",
        placeholder: "Has RPH supported this organization or event before?*",
        responsive: responsive.fullFormWidth,
        options: [
          { value: true, label: "Yes" },
          { value: false, label: "No" }
        ]
      },

      {
        name: "event_name",
        type: "text",
        placeholder: "Event Name",
        responsive: responsive.thirdFormWidth
      },
      {
        name: "status",
        type: "select",
        placeholder: "Event Status",
        responsive: responsive.thirdFormWidth,
        options: [
          { value: "pending", label: "Pending" },
          { value: "accepted", label: "Accepted" },
          { value: "rejected", label: "Rejected" }
        ]
      },
      {
        name: "request_type_2",
        type: "select",
        placeholder: "Event Type*",
        responsive: responsive.thirdFormWidth,
        options: [
          { value: "sponsorship", label: "Sponsorship" },
          { value: "grants", label: "Grants" },
          { value: "in_kind", label: "In Kind" },
          { value: "other", label: "Other" }
        ]
      },
      {
        name: "event_date_start",
        type: "date",
        maxDateIdentifier: "event_date_end",
        placeholder: "Event Start Date if any",
        responsive: responsive.thirdFormWidth
      },
      {
        name: "event_date_end",
        type: "date",
        minDateIdentifier: "event_date_start",
        placeholder: "Event End Date if any",
        responsive: responsive.thirdFormWidth
      },
      {
        name: "event_date_deadline",
        type: "date",
        placeholder: "Commitment Deadline if any",
        responsive: responsive.thirdFormWidth
      },
      {
        name: "event_budget_date",
        type: "date",
        placeholder: "Event Budget Date",
        responsive: responsive.thirdFormWidth,
      },
      
    ]
  },
  contactInformation: {
    label: "Contact Information",
    fields: [
      {
        name: "request_type_1",
        type: "select",
        placeholder: "Type of Request*",
        responsive: responsive.halfFormWidth,
        options: [
          {
            value: "charity_and_fundraising",
            label: "Charity and Fundraising (Cash Donation)"
          },
          { value: "general_sponsorship", label: "General Sponsorship" },
          { value: "donation_request", label: "Donation Request" },
          { value: "partnership_request", label: "Partnership Request" },
          { value: "in_kind_contribution", label: "In-Kind Contribution" }
        ]
      },
      {
        name: "org_name",
        type: "text",
        placeholder: "Company Name*",
        responsive: responsive.halfFormWidth
      },
      {
        name: "contact_first_name",
        type: "text",
        placeholder: "Contact First Name*",
        responsive: responsive.halfFormWidth
      },
      {
        name: "contact_last_name",
        type: "text",
        placeholder: "Contact Last Name*",
        responsive: responsive.halfFormWidth
      },
      {
        name: "contact_title",
        type: "text",
        placeholder: "Contact Job Title*",
        responsive: responsive.halfFormWidth
      },
      {
        name: "contact_email",
        type: "text",
        placeholder: "Contact Email Address*",
        responsive: responsive.halfFormWidth
      },
      {
        name: "contact_phone_number",
        type: "tel",
        placeholder: "Contact Phone Number*",
        responsive: responsive.halfFormWidth
      }
    ]
  },
  organizationInformation: {
    label: "Organization Information",
    fields: [
      {
        name: "is_org_exempt_in_501c3",
        type: "select",
        placeholder:
          "Is your organization excempt under section 501(c)3 of the IRS tax code*",
        responsive: responsive.halfFormWidth,
        options: [
          { value: true, label: "Yes" },
          { value: false, label: "No" }
        ]
      },

      {
        name: "org_primary_focus",
        type: "select",
        placeholder: "Primary Focus of Organization*",
        responsive: responsive.halfFormWidth,
        options: [
          { value: "arts", label: "Arts" },
          { value: "education", label: "Education" },
          { value: "youth", label: "Youth" },
          { value: "health_and_wellness", label: "Health and Wellness" },
          { value: "other", label: "Other" }
        ]
      },
      {
        name: "org_purpose",
        component: "textarea",
        row: 5,
        placeholder: "Organization's Mission Statement (Purpose)*",
        responsive: responsive.fullFormWidth
      },
      {
        name: "non_profit_tax_id_number",
        type: "text",
        placeholder: "Enter Non-profit Tax ID Number*",
        responsive: responsive.halfFormWidth
      },
      {
        name: "org_status",
        type: "select",
        placeholder: "(IRS): Non-profit Organization Status*",
        responsive: responsive.halfFormWidth,
        options: [
          { value: "status_501c3", label: "501(c)(3)" },
          {
            value: "educational_institution",
            label: "Educational Institution"
          },
          { value: "other", label: "Other" }
        ]
      },
      {
        name: "org_legal_name",
        type: "text",
        placeholder: "(IRS): Organization Legal Name*",
        responsive: responsive.halfFormWidth
      },
      {
        name: "org_address",
        type: "text",
        placeholder: "(IRS): Organization Address*",
        responsive: responsive.halfFormWidth
      },
      {
        name: "org_city",
        type: "text",
        placeholder: "(IRS): Organization City*",
        responsive: responsive.halfFormWidth
      },
      {
        name: "org_state",
        type: "select",
        placeholder: "(IRS): Organization State*",
        responsive: responsive.halfFormWidth,
        options: states
      },
      {
        name: "org_zip_code",
        type: "num",
        placeholder: "(IRS): Organization Zip Code*",
        responsive: responsive.halfFormWidth
      },
      {
        name: "org_web_address",
        type: "text",
        placeholder: "Organization Web Address (URL)*",
        responsive: responsive.halfFormWidth
      },
      {
        name: "org_list_of_board_of_directors",
        component: "textarea",
        row: 5,
        placeholder: "List of Board of Directors (can provide link)",
        responsive: responsive.fullFormWidth
      },

      {
        name: "notes",
        component: "textarea",
        row: 5,
        placeholder: "Notes (Internal Use Only)",
        responsive: responsive.fullFormWidth
      }
    ]
  },
  orgDocuments: {
    label: "",
    fields: [
      {
        name: "org_documents",
        type: "file",
        hasFile: true,
        multiple: true,
        responsive: responsive.fullFormWidth
      }
    ]
  }
};

export default requestFormTemplate;
