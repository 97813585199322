import React, { Fragment, useState, useRef, useEffect } from "react";
import { Formik, Form, Field, FieldArray } from "formik";
import DatePickerField from "./DatePickerField";
import SelectPickerField from "./SelectPickerField";
import MaskedInputField from "./MaskedInputField";
import FileUploadField from "./FileUploadField";
import FieldErrorMessage from "./FieldErrorMessage";
import ErrorFocus from "./ErrorFocus";
import FileTable from "./FileTable";
import moment from "moment";

const CustomForm = ({
  enableReinitialize = false,
  initialValues = {},
  validationSchema = null,
  onSubmit,
  formTemplate,
  btnClass = "",
  btnLabel = "Submit",
  fixedPlaceholder = false,
  removeAttachment = () => {},
  hasErrorFocus = false,
  hasErrorMsg = "Submission Invalid. See errors below.",
}) => {
  const [hasValidationError, setHasValidationError] = useState(false);
  const errorRef = useRef(null);

  useEffect(() => {
    if (hasValidationError && errorRef && window) {
      window.scrollTo(1000, errorRef.current.offsetTop - 20);
    }
  }, [hasValidationError]);

  const onValidationError = () => {
    setHasValidationError(true);
  };

  return (
    <Fragment>
      {hasErrorFocus && hasValidationError && (
        <div
          ref={errorRef}
          className="alert alert-danger alert-dismissible fade show"
          role="alert"
        >
          {hasErrorMsg}
          <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
            onClick={() => setHasValidationError(false)}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      )}
      <Formik
        enableReinitialize={enableReinitialize}
        initialValues={initialValues ? initialValues : {}}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ values, isSubmitting, isValidating, errors, touched }) => (
          <Form>
            {hasErrorFocus && (
              <ErrorFocus
                {...{ isSubmitting, isValidating, errors, onValidationError }}
              />
            )}
            {Object.keys(formTemplate).map((groupKey) => {
              return (
                <Fragment key={groupKey}>
                  {formTemplate[groupKey].label && (
                    <h1 className="text-center">
                      {formTemplate[groupKey].label}
                    </h1>
                  )}
                  <div className="form-row">
                    {formTemplate[groupKey].fields.map((field, key) => {
                      let { name, responsive, type, text, ...rest } = field;
                      if (type == "select") {
                        const { placeholder, ...props } = rest;
                        return (
                          <div key={key} className={`form-group ${responsive}`}>
                            {fixedPlaceholder && <label>{placeholder}</label>}
                            <SelectPickerField
                              {...props}
                              name={name}
                              placeholder={placeholder}
                              fixedPlaceholder={fixedPlaceholder}
                              styles={{
                                control: (styles) => ({
                                  ...styles,
                                  borderColor:
                                    errors[name] && touched[name]
                                      ? "red"
                                      : styles.borderColor,
                                }),
                              }}
                              value={
                                initialValues[name] != undefined
                                  ? initialValues[name]
                                  : null
                              }
                            />
                            <FieldErrorMessage
                              error={errors[name]}
                              touched={touched[name]}
                            />
                          </div>
                        );
                      } else if (type == "date") {
                        const {
                          placeholder,
                          minDateIdentifier,
                          maxDateIdentifier,
                          ...props
                        } = rest;
                        let min =
                          minDateIdentifier && values[minDateIdentifier]
                            ? values[minDateIdentifier]
                            : "2015/01/01";
                        let max =
                          maxDateIdentifier && values[maxDateIdentifier]
                            ? values[maxDateIdentifier]
                            : "2030/01/01";
                        return (
                          <div key={key} className={`form-group ${responsive}`}>
                            <label className="w-100">
                              {placeholder}
                              <DatePickerField
                                {...props}
                                name={name}
                                minDate={new Date(moment(min))}
                                maxDate={new Date(moment(max))}
                                className={`form-control py-3 ${
                                  errors[name] && touched[name]
                                    ? "is-invalid"
                                    : ""
                                }`}
                              />
                            </label>
                            <FieldErrorMessage
                              error={errors[name]}
                              touched={touched[name]}
                            />
                          </div>
                        );
                      } else if (type == "checkbox") {
                        return (
                          <div key={key} className={`form-group ${responsive}`}>
                            <Field
                              {...rest}
                              name={name}
                              className="form-control py-3"
                            >
                              {({ field }) => (
                                <label>
                                  {text && text}
                                  <input
                                    {...field}
                                    className="ml-2 p-2"
                                    type="checkbox"
                                    checked={field.value ? true : false}
                                  />
                                </label>
                              )}
                            </Field>
                            <FieldErrorMessage
                              error={errors[name]}
                              touched={touched[name]}
                            />
                          </div>
                        );
                      } else if (type == "tel") {
                        const { placeholder, ...props } = rest;
                        return (
                          <div key={key} className={`form-group ${responsive}`}>
                            {fixedPlaceholder && <label>{placeholder}</label>}
                            <MaskedInputField
                              {...props}
                              name={name}
                              type={type}
                              placeholder={placeholder}
                              fixedPlaceholder={fixedPlaceholder}
                              className={`form-control py-3 ${
                                errors[name] && touched[name]
                                  ? "is-invalid"
                                  : ""
                              }`}
                            />
                            <FieldErrorMessage
                              error={errors[name]}
                              touched={touched[name]}
                            />
                          </div>
                        );
                      } else if (type == "file") {
                        const { hasFile, ...props } = rest;
                        return (
                          <div key={key} className={`form-group ${responsive}`}>
                            {!hasFile && (
                              <div className="row px-4">
                                <div className="w-75 d-flex align-items-center">
                                  <p>
                                    Please upload a copy of your organization's
                                    tax-exempt letter <br /> including the tax
                                    identification number.
                                  </p>
                                </div>
                                <div className="w-25">
                                  <FileUploadField
                                    {...{ name, type, ...props }}
                                    id="custom-file-input"
                                    className="w-100 py-3"
                                  />
                                </div>
                              </div>
                            )}
                            {hasFile && (
                              <div className="col-sm-12 col-md-8 col-lg-6 col-xl-6 offset-md-2 offset-lg-3 offset-xl-3">
                                <h4>Attachments</h4>
                                <FileTable
                                  {...{ removeAttachment }}
                                  files={
                                    initialValues["documents"]
                                      ? initialValues["documents"]
                                      : []
                                  }
                                />
                                <FileUploadField
                                  {...{ name, type, ...props }}
                                  id="custom-file-input"
                                  className="w-100 py-3"
                                />
                              </div>
                            )}
                          </div>
                        );
                      } else {
                        const { placeholder, ...props } = rest;
                        return (
                          <div key={key} className={`form-group ${responsive}`}>
                            {fixedPlaceholder && <label>{placeholder}</label>}
                            <Field
                              {...props}
                              name={name}
                              type={type}
                              placeholder={!fixedPlaceholder ? placeholder : ""}
                              className={`form-control py-3 ${
                                errors[name] && touched[name]
                                  ? "is-invalid"
                                  : ""
                              }`}
                            />
                            <FieldErrorMessage
                              error={errors[name]}
                              touched={touched[name]}
                            />
                          </div>
                        );
                      }
                    })}
                  </div>
                </Fragment>
              );
            })}
            <div className="w-100 text-center">
              <button
                type="submit"
                className={`btn btn-dark px-4 py-2 ${btnClass}`}
                disabled={isSubmitting}
              >
                {btnLabel}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </Fragment>
  );
};

export default CustomForm;
