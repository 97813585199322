import React, { Fragment, useState, useEffect } from "react";
import moment from 'moment';
import Select from "react-select";

import Api from "../../services/Api";
import Utilities from "../../services/Utilities";
import ModalPage from "../ui/ModalPage";
import DivWithErrorHandling from "../ui/DivWithErrorHandling";
import RequestBudget from "./modal/RequestBudget";
import UpdateBudget from "./modal/UpdateBudget";
import BudgetTable from "./tables/budget/BudgetTable";
import useModal from "../../global/useModal";
import useBudget from "../../global/useBudget";
import yearOption from "../../data/yearOption";

const Budget = () => {
  const [modalState, modalAction] = useModal();
  const [budgetState, budgetAction] = useBudget();
  const [isFetching, setIsFetching] = useState(false);
  const [codeOptionState, setCodeOptionState] = useState({
    loading: false,
    codeOptions: [],
  });
  const [error, setError] = useState(false);
  const [code, setCode] = useState(null);

  useEffect(() => {
    if (!isFetching) {
      setIsFetching(true);
      getBudgetSummary();
    }
  }, [budgetState.year, code]);

  useEffect(() => {
    getRequestLegends();
    getCodeOptions();
    return () => {
      modalAction.setModal(false);
    };
  }, []);

  const getBudgetSummary = async () => {
    let params = getSearchParams();
    try {
      const result = await Api.getData(`budgets/budget_summary?${params}`);
      setIsFetching(false);
      if (result) {
        budgetAction.setGlobalState({ budget: result });
      }
    } catch (error) {
      console.log("Error", error);
      setError(true);
    }
  };

  const getCodeOptions = async (attempts = 1) => {
    setCodeOptionState((prev) => ({ ...prev, loading: true }));
    try {
      const { request_type_1 } = await Api.getData(`requests/options`);
      if (request_type_1) {
        let tmpOptions = [];
        Object.keys(request_type_1).map((type) => {
          tmpOptions.push({
            value: type,
            label: request_type_1[type],
          });
        });
        setCodeOptionState((prev) => ({
          ...prev,
          codeOptions: tmpOptions,
          loading: false,
        }));
      }
    } catch (error) {
      console.log("Error", error);
      setCodeOptionState((prev) => ({ ...prev, loading: false }));
      if (attempts < 3) {
        getCodeOptions(attempts + 1);
      }
    }
  };

  const getRequestLegends = async (attempts = 1) => {
    try {
      const { collection } = await Api.getData(`request_legends?per_page=999`);
      budgetAction.setGlobalState({ legends: collection });
    } catch (error) {
      console.log("Error", error);
      if (attempts < 3) {
        getRequestLegends(attempts + 1);
      }
    }
  };

  const getSearchParams = () => {
    let requestTargetDate = "event_budget_date";
    let selectedYear;
    let filters = {
      ...(code && { request_type_1_eq: code.value }),
      status_eq: "accepted",
    };

    if (!budgetState.year) {
      // Default to current year
      selectedYear = moment().format("YYYY");
      budgetAction.setGlobalState({
        year: { value: selectedYear, label: selectedYear },
      });
    } else {
      selectedYear = budgetState.year.value;
    }
    let parsedSearchParams = Utilities.serializeRansackParams(filters);

    return `year=${selectedYear}&request_target_date=${requestTargetDate}&${
      parsedSearchParams ? parsedSearchParams : ""
    }`;
  };

  const exportCSV = () => {
    let params = getSearchParams();
    return `/api/v1/budgets/budget_summary.xlsx?${params}`;
  };

  const closeModal = () => {
    budgetAction.setGlobalState({ modalType: null, month: null });
    modalAction.setModal(false);
  };

  return (
    <DivWithErrorHandling {...{ error }}>
      <ModalPage>
        {budgetState.modalType == "requestDetail" && <RequestBudget />}
        {budgetState.modalType == "updateBudget" && (
          <UpdateBudget {...{ closeModal }} />
        )}
      </ModalPage>
      {budgetState.budget && (
        <div className="row mx-1">
          <div className="col-sm-12 col-md-12 col-lg-3 col-xl-2">
            <h3 className="my-2">View By Year</h3>
            <Select
              value={budgetState.year}
              onChange={(year) => budgetAction.setGlobalState({ year: year })}
              options={yearOption}
            />
            {}
            <h3 className="mt-4 mb-2">View By Code</h3>
            <Select
              value={code}
              onChange={setCode}
              options={codeOptionState.codeOptions}
            />
            <h3 className="mt-4 mb-2">Tools</h3>
            <a className="btn btn-dark" href={exportCSV()} target="_blank">
              Export CSV
            </a>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-9 col-xl-10">
            <BudgetTable />
          </div>
        </div>
      )}
    </DivWithErrorHandling>
  );
};

export default Budget;
