import React, { useState, useEffect, useRef } from "react";

import CustomForm from "../ui/CustomForm";
import LoadingView from "../ui/LoadingView";
import SubmissionTable from "./tables/submissions/SubmissionTable";
import searchFormTemplate from "../../data/formTemplates/searchFormTemplate";
import Api from "../../services/Api";
import Utilities from "../../services/Utilities";


const Submissions = ({ status }) => {
  const [pageInfo, setPageInfo] = useState({
    hasMoreLoading: false,
    page: 0,
    totalPages: null,
  });
  const [submissions, setSubmissions] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [searchFields, setSearchFields] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [filters, setFilters] = useState({
    event_name: null,
    event_date_start: null,
    event_amount_request: null,
    status: null,
    updated_at: null,
    created_at: null,
  });

  useEffect(() => {
    if (pageInfo.page == 0) {
      getForms();
    }
  }, [pageInfo.page]);

  const parseSubmitValues = (val) => {
    var data = {};
    Object.keys(val).map((key) => {
      let type = typeof val[key];

      if (!val[key]) return false;

      if (type == "object") {
        data[key] = val[key].value;
      } else {
        data[key] = val[key];
      }
    });
    return data;
  };

  const getForms = async () => {
    let newPage = pageInfo.page + 1;

    // If triggered by load more.
    if (newPage > 1) {
      setPageInfo((prev) => ({ ...prev, hasMoreLoading: true }));
    }

    let parsedSearchParams = getParams();
    let encoded = `page=${newPage}&per_page=15&` + parsedSearchParams;

    try {
      const { collection, total_pages, current_page } = await Api.getData(
        `requests?${encoded}`
      );

      setSubmissions((prevSub) => [...prevSub, ...collection]);

      setPageInfo({
        hasMoreLoading: false,
        page: current_page,
        totalPages: total_pages,
      });

      if (current_page >= total_pages) {
        setHasMore(false);
      }

      setLoading(false);
    } catch (error) {
      console.log(error);
      setPageInfo((prev) => ({ ...prev, hasMoreLoading: false }));
      setError(true);
    }
  };

  const getParams = () => {
    let params = {
      ...(searchFields.keyword && {
        org_name_or_event_name_cont: searchFields.keyword,
      }),
      ...(searchFields.start_date && {
        event_date_start_gteq: searchFields.start_date,
      }),
      ...(searchFields.end_date && {
        event_date_end_lteq: searchFields.end_date,
      }),
      ...(status && { status_eq: status }),
    };

    Object.keys(filters).map((filter) => {
      if (filters[filter]) {
        params["s"] = `${filter} ${filters[filter]}`;
      }
    });

    return Utilities.serializeRansackParams(params);
  };

  const exportCSV = () => {
    let params = getParams();
    return `/api/v1/requests.xlsx?per_page=99999&${params}`;
  };

  const removeRequest = async (request) => {
    let choice = await Utilities.alertConfirm(
      "Confirm Delete",
      `Are you sure you want to remove ${request.event_name} request?`,
      "Yes",
      "No"
    );
    if (choice) {
      try {
        const result = await Api.deleteData(`requests/${request.id}`);
        searchForms();
      } catch (error) {
        console.log(error);
      }
    }
  };

  const duplicateRequest = async (request) => {
    let choice = await Utilities.alertConfirm(
      "Confirm Duplication",
      `Are you sure you want to duplicate ${request.event_name} request?`,
      "Yes",
      "No"
    );
    if (choice) {
      try {
        const result = await Api.duplicateData(`requests/${request.id}/duplicate`);
        searchForms();
      } catch (error) {
        console.log(error);
      }
    }
  }

  const searchForms = async (values = {}) => {
    setLoading(true);
    let parsedValues = {};

    if (values) {
      parsedValues = await parseSubmitValues(values);
      setSearchFields(parsedValues);
    }
    resetSubmissionData();
  };

  const applyFilter = (type) => {
    let newType;
    if (!filters[type] || filters[type] == "DESC") {
      newType = "ASC";
    } else {
      newType = "DESC";
    }
    let newFilters = {
      [type]: newType,
    };
    Object.keys(filters).map((filter) => {
      if (filter != type) {
        newFilters[filter] = null;
      }
    });
    setFilters({ ...newFilters });
    resetSubmissionData();
  };

  const resetSubmissionData = () => {
    setSubmissions([]);
    setHasMore(true);
    setPageInfo({
      page: 0,
      totalPages: null,
    });
  };

  return (
    <div className="container-fluid mt-2">
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-4 col-xl-3">
          <div className="card">
            <div className="card-body">
              <h2>Search</h2>
              <p>
                Enter a keyword below to search upon most fields attached to
                sponsorship records.
              </p>
              <CustomForm
                initialValues={{
                  keyword: "",
                  start_date: null,
                  end_date: null,
                }}
                fixedPlaceholder={true}
                enableReinitialize={true}
                formTemplate={searchFormTemplate}
                onSubmit={searchForms}
                btnClass="btn-block"
                btnLabel="Search"
              />
              <a
                className="btn btn-dark w-100 mt-2 p-2"
                href={exportCSV()}
                target="_blank"
              >
                Export CSV
              </a>
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-8 col-xl-9">
          <div className="card">
            <div className="card-body requestContainer">
              <LoadingView {...{ loading, error }}>
                <SubmissionTable
                  {...{
                    submissions,
                    hasMore,
                    pageInfo,
                    getForms,
                    removeRequest,
                    duplicateRequest,
                    filters,
                    applyFilter,
                  }}
                />
              </LoadingView>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Submissions;
