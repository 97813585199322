import React, { useState } from "react";
import useBudget from "../../../../global/useBudget";

const ColorSelector = ({ changeColor, selectedColor }) => {
  const [budgetState, budgetAction] = useBudget();
  return (
    <div className="row w-full">
      {budgetState.legends &&
        budgetState.legends.map((legend, index) => (
          <div
            className="col-4 mb-2"
            key={index}
            onClick={() => changeColor(legend.id)}
          >
            <div
              className="w-full text-center py-2 text-dark border min-height-47"
              style={{ backgroundColor: legend.color }}
            >
              {legend.label}
              {legend.id == selectedColor && (
                <span className="text-dark pl-2">✓</span>
              )}
            </div>
          </div>
        ))}
    </div>
  );
};

ColorSelector.propTypes = {};

export default ColorSelector;
