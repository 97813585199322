export default {
  request_type_1: "",
  org_name: "",
  contact_first_name: "",
  contact_last_name: "",
  contact_title: "",
  contact_email: "",
  contact_phone_number: "",
  is_org_exempt_in_501c3: "",
  org_primary_focus: "",
  org_purpose: "",
  non_profit_tax_id_number: "",
  org_status: "",
  org_legal_name: "",
  org_address: "",
  org_city: "",
  org_state: "",
  org_zip_code: "",
  org_web_address: "",
  org_list_of_board_of_directors: "",
  event_name: "",
  request_type_2: "",
  event_date_start: "",
  event_date_end: "",
  event_date_deadline: "",
  event_budget_date: "",
  event_description_of_opportunity: "",
  event_amount_request: "",
  event_purpose_contribution_request: "",
  event_description_of_request: "",
  is_event_supported_before: "",
  is_sender_legal_age: "",
  status: ""
};
