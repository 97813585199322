import React from "react";
import Lottie from "react-lottie";
import * as animationData from "../../../assets/lottie/success.json";

const UpdateSuccessful = ({ desc, cb }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData.default,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };
  return (
    <div className="modalContainer p-2 text-center">
      <Lottie options={defaultOptions} height={150} width={150} />
      <h2 className="p-1">Awesome!</h2>
      <h3 className="text-center py-2">{desc}</h3>
      <button type="button" onClick={cb} className="btn btn-dark">
        Done
      </button>
    </div>
  );
};

export default UpdateSuccessful;
