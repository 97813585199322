import React, { useState, useEffect } from "react";
import CustomForm from "../../ui/CustomForm";
import Api from "../../../services/Api";
import useModal from "../../../global/useModal";
import useBudget from "../../../global/useBudget";
import updateBudgetTemplate from "../../../data/formTemplates/updateBudgetTemplate";
import { budgetSchema } from "../../../data/validationSchema";

const UpdateBudget = ({closeModal}) => {
  const [modalState, modalAction] = useModal();
  const [budgetState, budgetAction] = useBudget();
  const [initialValue, setInitialValue] = useState({ amount: "" });

  useEffect(() => {
    if (budgetState.selectedBudget) {
      setInitialValue({
        amount: budgetState.selectedBudget.amount,
      });
    }
  }, [budgetState.selectedBudget]);

  const saveBudget = async (values) => {
    try {
      var data = new FormData();
      var contentType = { "Content-Type": "multipart/form-data" };
      var result = null;
      data.append("amount", values.amount);

      if (budgetState.selectedBudget) {
        // Update budget
        result = await Api.patchData(
          `budgets/${budgetState.selectedBudget.id}`,
          data,
          contentType
        );
      } else {
        // Create budget
        data.append("year", budgetState.year.value);
        data.append("month", budgetState.month);
        result = await Api.postData(`budgets`, data, contentType);
      }

      if (result) {
        budgetState.budget[result.year].months[result.month].budget = result;
      }

      await recalculateBudget();
      closeModal();
    } catch (error) {
      console.log(error);
    }
  };

  const recalculateBudget = () => {
    let budget = 0;
    let totalDifference = 0;
    let months = budgetState.budget[budgetState.year.value].months;

    Object.keys(months).map((month) => {
      let budgetData = months[month].budget;
      let difference = 0;
      if (budgetData) {
        budget = parseFloat(budget) + parseFloat(budgetData.amount);
        difference =
          parseFloat(budgetData.amount) -
          parseFloat(months[month].total_event_amount_request);
        months[month].calculated_difference = difference.toFixed(2);
      }
    });

    totalDifference =
      budget -
      parseFloat(
        budgetState.budget[budgetState.year.value].total.event_amount_request
      );
    budgetState.budget[
      budgetState.year.value
    ].total.calculated_difference = `${totalDifference.toFixed(2)}`;

    budgetState.budget[budgetState.year.value].total.budget = `${budget.toFixed(
      2
    )}`;
  };

  return (
    <div className="p-2">
      <button
        type="button"
        onClick={closeModal}
        className="closeBtnModal btn btn-link"
      >
        x
      </button>
      <h3 className="text-center py-2">Enter your budget for this month.</h3>
      <CustomForm
        initialValues={initialValue}
        validationSchema={budgetSchema}
        formTemplate={updateBudgetTemplate}
        onSubmit={saveBudget}
        enableReinitialize={true}
        btnLabel="Submit"
      />
    </div>
  );
};

export default UpdateBudget;
