import React, { useState, useEffect } from "react";
import CustomForm from "../ui/CustomForm";
import GoBack from "../ui/GoBack";
import ModalPage from "../ui/ModalPage";
import UpdateSuccessful from "../ui/UpdateSuccessful";
import updateFormTemplate from "../../data/formTemplates/updateFormTemplate";
import { requestSchema } from "../../data/validationSchema";
import Api from "../../services/Api";
import Utilities from "../../services/Utilities";
import useModal from "../../global/useModal";
import WithLoading from "../../hoc/WithLoading";

const UpdateRequestForm = ({ request }) => {
  const [modalState, modalAction] = useModal();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [requestData, setRequestData] = useState({});

  useEffect(() => {
    getSubmission();
  }, []);

  const getSubmission = async () => {
    try {
      setLoading(true);
      const result = await Api.getData(`requests/${request.id}`);
      let tmpRequest = result;
      tmpRequest["documents"] = result.org_documents;
      delete tmpRequest.org_documents;
      setRequestData(tmpRequest);
      setLoading(false);
    } catch (error) {
      setError(true);
    }
  };

  const removeAttachment = async index => {
    let choice = await Utilities.alertConfirm(
      "Confirm Remove",
      `Are you sure you want to remove attachment?`,
      "Yes",
      "No"
    );
    if (choice) {
      try {
        const result = await Api.patchData(`requests/${request.id}`, {
          remove_org_documents_by_ids: `${index}`
        });
        let tmpRequest = result;
        tmpRequest["documents"] = result.org_documents;
        delete tmpRequest.org_documents;
        setRequestData(tmpRequest);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const onSubmit = async values => {
    let choice = await Utilities.alertConfirm(
      "Confirm Update",
      `Are you sure you want to update request?`,
      "Yes",
      "No"
    );
    if (choice) {
      const { file, ...rest } = values;
      let tmpValues = await { org_documents: file, ...rest };
      let parsedValue = await parseSubmitValues(tmpValues);
      try {
        const result = await Api.patchData(
          `requests/${request.id}`,
          parsedValue,
          { "Content-Type": "multipart/form-data" }
        );
        let tmpRequest = result;
        tmpRequest["documents"] = result.org_documents;
        delete tmpRequest.org_documents;
        setRequestData(tmpRequest);
        modalAction.setModal(true);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const parseSubmitValues = val => {
    var data = new FormData();
    Object.keys(val).map(key => {
      let type = typeof val[key];
      if (type == "object" && key != "org_documents" && val[key] != null) {
        data.append(key, val[key].value);
      } else {
        if (key == "org_documents") {
          if (val[key] != undefined && val[key].length > 0) {
            val[key].forEach(file => {
              data.append(`${key}[]`, file);
            });
          }
        } else {
          data.append(key, val[key]);
        }
      }
    });
    return data;
  };

  const UseLoading = WithLoading(CustomForm, loading, error, {
    removeAttachment: removeAttachment,
    initialValues: requestData,
    validationSchema: requestSchema,
    formTemplate: updateFormTemplate,
    onSubmit: onSubmit,
    fixedPlaceholder: true,
    btnLabel: "Update"
  });

  return (
    <div className="my-3">
      <ModalPage>
        <UpdateSuccessful
          desc="Request was successfully updated!"
          cb={() => {
            modalAction.setModal(false);
            window.history.back();
          }}
        />
      </ModalPage>
      <GoBack cb={() => window.history.back()} />
      <UseLoading />
    </div>
  );
};

export default UpdateRequestForm;
