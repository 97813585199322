const ErrorFocus = ({
  isSubmitting,
  isValidating,
  errors,
  onValidationError,
}) => {
  const keys = Object.keys(errors);
  if (keys.length > 0 && isSubmitting && !isValidating) {
    onValidationError();
  }
  return null;
};

export default ErrorFocus;
