import React from "react";
import { useField, useFormikContext } from "formik";
import moment from 'moment';

import DatePicker from "react-datepicker";

const DatePickerField = ({ ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);
  return (
    <DatePicker
      {...field}
      {...props}
      dateFormat="yyyy/MM/dd"
      selected={(field.value && new Date(moment(field.value)))}
      onChange={val => {
        if (val) {
          let parsedDate = moment(val).format("YYYY-MM-DD");
          setFieldValue(field.name, parsedDate);
        }
      }}
    />
  );
};

export default DatePickerField;
