import React, { Fragment, useState, useEffect } from "react";
import { CirclePicker } from "react-color";
import Api from "../../../services/Api";
import useModal from "../../../global/useModal";
import useBudget from "../../../global/useBudget";
import Utilities from "../../../services/Utilities";
import ColorSelector from "../tables/budget/ColorSelector";

const RequestBudget = () => {
  const [selectedColor, setSelectedColor] = useState(null);
  const [modalState, modalAction] = useModal();
  const [budgetState, budgetAction] = useBudget();

  useEffect(() => {
    if (budgetState.selectedRequest) {
      setSelectedColor(budgetState.selectedRequest.request_legend_id);
    }
  }, [budgetState.selectedRequest]);

  const changeColor = (colorId) => {
    if (colorId == selectedColor) {
      setSelectedColor(null);
    } else {
      setSelectedColor(colorId);
    }
  };

  const saveLegend = async () => {
    if (selectedColor) {
      try {
        var data = new FormData();
        data.append("request_legend_id", selectedColor);
        const result = await Api.patchData(
          `requests/${budgetState.selectedRequest.id}`,
          data,
          { "Content-Type": "multipart/form-data" }
        );
        await updateRequest();
        closeModal();
      } catch (error) {
        console.log(error);
      }
    } else {
      closeModal();
    }
  };

  const updateRequest = async () => {
    let months = budgetState.budget[budgetState.year.value].months;
    let selectedRequestId = budgetState.selectedRequest.id;
    if (months) {
      Object.keys(months).map((month) => {
        let tmpRequests = months[month].requests;
        let index = tmpRequests.findIndex((req) => {
          return req.id == selectedRequestId;
        });
        if (index != -1) {
          let newData = tmpRequests[index];
          newData.request_legend_id = selectedColor;
          tmpRequests.splice(index, 1, newData);
        }
        return tmpRequests;
      });
    }
  };

  const closeModal = () => {
    budgetAction.setGlobalState({
      selectedRequest: null,
      modalType: null,
    });
    modalAction.setModal(false);
  };

  return (
    <div className="m-2 pt-2">
      <button
        type="button"
        onClick={closeModal}
        className="closeBtnModal btn btn-link"
      >
        x
      </button>
      {budgetState.selectedRequest ? (
        <Fragment>
          <a href={`/admin/requests/${budgetState.selectedRequest.id}/edit`}>
            <h2>{budgetState.selectedRequest.org_name}</h2>
          </a>
          <p>
            <b>Submission Date: </b>
            {new Date(budgetState.selectedRequest.created_at).toDateString()}
          </p>
          <p>
            <b>Event Type: </b>
            {budgetState.selectedRequest.request_type_1}
          </p>
          <p>
            <b>Cost: </b>
            {Utilities.formatCurrency(
              budgetState.selectedRequest.event_amount_request
            )}
          </p>
          <p>
            <b>Location: </b>
            {budgetState.selectedRequest.org_address}
          </p>
          <p>
            <b>Uploaded Documents: </b>
            {budgetState.selectedRequest.org_documents.length == 0 &&
              "No attached document"}
          </p>

          {budgetState.selectedRequest.org_documents.length > 0 && (
            <div className="list-group">
              {budgetState.selectedRequest.org_documents.map((docu) => (
                <a
                  href={docu.url}
                  target="_blank"
                  className="list-group-item list-group-item-action"
                >
                  {docu.filename}
                </a>
              ))}
            </div>
          )}
          <hr />
          <p>
            <b>Cell Color</b>
          </p>
          <div className="d-flex justify-content-center align-items-center flex-column mt-2">
            <ColorSelector {...{ changeColor, selectedColor }} />
            <button
              type="button"
              className="btn btn-dark mt-4"
              onClick={() => saveLegend()}
            >
              Save
            </button>
          </div>
        </Fragment>
      ) : (
        <h3 className="text-center">No request selected</h3>
      )}
    </div>
  );
};

export default RequestBudget;
