import React from "react";
import BudgetRequestData from "./BudgetRequestData";

const BudgetRequestRow = ({ data }) => {
  return (
    <tr>
      <td className="text-right"></td>
      {data &&
        Object.keys(data.months).map(month => (
          <td className="p-0" key={month}>
            {data.months[month].requests.length > 0 &&
              data.months[month].requests.map((req, innerIndex) => (
                <BudgetRequestData data={req} key={innerIndex} />
              ))}
          </td>
        ))}
      <td className="text-right"></td>
    </tr>
  );
};

export default BudgetRequestRow;
