import * as Yup from "yup";

const budgetSchema = Yup.object().shape({
  amount: Yup.number().required("Requested amount is required"),
});

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/


const requestSchema = Yup.object().shape({
  request_type_1: Yup.string().required("Type of Request is required"),
  request_type_2: Yup.string().required("Event Type is required"),
  org_name: Yup.string().required("Company Name is required"),
  contact_first_name: Yup.string().required("First Name is required"),
  contact_last_name: Yup.string().required("Last Name is required"),
  contact_email: Yup.string()
    .email("Email address must be valid")
    .required("Email address is required"),
  contact_phone_number: Yup.string().required("Phone Number is required").matches(phoneRegExp, 'Phone number is not valid'),
  contact_title: Yup.string().required("Title is required"),
  is_org_exempt_in_501c3: Yup.string().required("Required"),
  org_primary_focus: Yup.string().required("Primary Focus of Organization is Required"),
  org_purpose: Yup.string().required("Organization's Purpose is required"),
  non_profit_tax_id_number: Yup.string().required("Tax ID number is required"),
  org_status: Yup.string().required("Organization Status is required"),
  org_legal_name: Yup.string().required("Legal Name is required"),
  org_address: Yup.string().required("Address is required"),
  org_city: Yup.string().required("City is required"),
  org_state: Yup.string().required("State is required"),
  org_zip_code: Yup.string().required("Zip Code is required"),
  event_amount_request: Yup.string().required("Requested amount is required"),
  event_purpose_contribution_request: Yup.string().required(
    "Purpose of contribution is required"
  ),
  event_description_of_request: Yup.string().required(
    "Description of request is required"
  ),
  is_event_supported_before: Yup.string().required("Required"),
  is_sender_legal_age: Yup.boolean().required(
    "You must be 18 or Older to proceed."
  ),
});

export { requestSchema, budgetSchema };
