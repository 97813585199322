import React, { Fragment } from "react";

const StatsTable = ({ title, total }) => {
  return (
    <div className="table-container mt-2 col-md-6 col-lg-6 col-xl-6">
      <h2 className="text-center">{title}</h2>
      <table className="table">
        <thead className="thead-light">
          <tr>
            <th scope="col"></th>
            <th scope="col">Total</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="pt-3">Number of Requests</td>
            <td className="pt-3">{total}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default StatsTable;
