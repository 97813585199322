import React from "react";
import Loader from "../components/ui/Loader";

const WithLoading = (Component, loading, error = false, rest = {}) => {
  return function WihLoadingComponent() {
    if (error) {
      return (
        <div className="alert alert-danger" role="alert">
          Something went wrong!
        </div>
      );
    }

    if (loading && !error) {
      return (
        <div className="d-flex mt-4 justify-content-center">
          <Loader />
        </div>
      );
    }

    return <Component {...rest} />;
  };
};

export default WithLoading;
