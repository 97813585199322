import React, { useEffect, useState } from "react";
import moment from 'moment';
import Api from "../../services/Api";
import DivWithErrorHandling from "../ui/DivWithErrorHandling";

import StatsTable from "./tables/stats/StatsTable";
import NumberOfApprovedTables from "./tables/approved/NumberOfApprovedTables";

const Stats = () => {
  const [stats, setStats] = useState({
    totalAccepted: null,
    totalSubmissions: null,
  });
  const [summary, setSummary] = useState(null);
  const [error, setError] = useState(false);

  useEffect(() => {
    getStats();
  }, []);

  const getStats = async () => {
    try {
      let yearToday = moment().format("YYYY");
      let yearInterval = 5;
      let yearStart = yearToday - yearInterval;
      const totalRequest = await Api.getData(`requests_stats?per_page=999&exclude_empty_years=true&year_start=${yearStart}&year_end=${yearToday}`);
      const totalAccepted = await Api.getData(
        `requests_stats?per_page=999&exclude_empty_years=true&q[status_eq]=accepted&year_start=${yearStart}&year_end=${yearToday}`
      );
      const summary = await Api.getData(
        `requests_stats/summary?year_start=${yearToday}&year_end=${yearToday}`
      );
      setStats({
        totalSubmissions: totalRequest.collection,
        totalAccepted: totalAccepted.collection,
      });
      setSummary(summary);
    } catch (error) {
      console.log("Error", error);
      setError(true);
    }
  };

  return (
    <DivWithErrorHandling {...{ error }}>
      <div className="mx-2">
        {stats.totalSubmissions && (
          <NumberOfApprovedTables
            title="Number of Total Submissions Based on Month Submitted"
            data={stats.totalSubmissions}
          />
        )}

        {stats.totalAccepted && (
          <NumberOfApprovedTables
            title="Number of Accepted Submissions Based on Month Submitted"
            data={stats.totalAccepted}
          />
        )}

        {summary && (
          <div className="row">
            <div className="col-12 my-2">
              <h1 className="text-center">THIS YEAR</h1>
            </div>
            <StatsTable
              title="Total Submissions (YTD)"
              total={summary.request_total}
            />
            <StatsTable
              title="Accepted Submissions (YTD)"
              total={summary.request_accepted_total}
            />
          </div>
        )}
      </div>
    </DivWithErrorHandling>
  );
};

export default Stats;
