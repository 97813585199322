export default [
  { value: 2015, label: 2015 },
  { value: 2016, label: 2016 },
  { value: 2017, label: 2017 },
  { value: 2018, label: 2018 },
  { value: 2019, label: 2019 },
  { value: 2020, label: 2020 },
  { value: 2021, label: 2021 },
  { value: 2022, label: 2022 },
  { value: 2023, label: 2023 },
  { value: 2024, label: 2024 },
  { value: 2025, label: 2025 },
  { value: 2026, label: 2026 },
  { value: 2027, label: 2027 },
  { value: 2028, label: 2028 },
  { value: 2029, label: 2029 },
  { value: 2030, label: 2030 },
  { value: 2031, label: 2031 },
  { value: 2032, label: 2032 },
  { value: 2033, label: 2033 },
  { value: 2034, label: 2034 },
  { value: 2035, label: 2035 },
  { value: 2036, label: 2036 },
  { value: 2037, label: 2037 },
  { value: 2038, label: 2038 },
  { value: 2039, label: 2039 },
  { value: 2040, label: 2040 }
];
