import React from "react";

const withErrorHandling = WrappedComponent => ({
  msg = "Something went wrong!",
  hasClose = true,
  error,
  children
}) => {
  return (
    <WrappedComponent>
      {error && (
        <div className="alert alert-danger" role="alert">
          {msg}
          <a
            onClick={() => window.location.reload()}
            className="btn alert-link"
            role="button"
          >
            Try Again
          </a>
          {hasClose && (
            <button
              type="button"
              className="close"
              data-dismiss="alert"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          )}
        </div>
      )}
      {children}
    </WrappedComponent>
  );
};

export default withErrorHandling;
