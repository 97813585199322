import React, { Fragment } from "react";
import Loader from "./Loader";

const LoadingView = ({
  children,
  loading = true,
  error = false,
  msg = "Something went wrong!",
  hasClose = true,
}) => {
  
  return (
    <Fragment>
      {/* Success */}
      {!loading && !error && children}

      {/* Loading */}
      {loading && (
        <div className="d-flex mt-4 justify-content-center">
          <Loader />
        </div>
      )}

      {/* Error */}
      {!loading && error && (
        <div className="alert alert-danger" role="alert">
          {msg}
          <a
            onClick={() => window.location.reload()}
            className="btn alert-link"
            role="button"
          >
            Try Again
          </a>
          {hasClose && (
            <button
              type="button"
              className="close"
              data-dismiss="alert"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          )}
        </div>
      )}
    </Fragment>
  );
};

export default LoadingView;
