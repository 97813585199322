import React from "react";
import ParsedDate from "../../../ui/ParsedDate";

const SubmissionRow = ({ submission, removeRequest, duplicateRequest }) => {
  return (
    <tr className="text-center">
      <td className="pt-3 text-capitalize text-left">{submission.org_name}</td>
      <td className="pt-3">
        <ParsedDate date={submission.created_at} parse="YYYY-MM-DD" />
      </td>
      <td className="pt-3">{submission.event_amount_request}</td>
      <td className="pt-3 text-capitalize">{submission.status}</td>
      <td className="pt-3">
        <ParsedDate date={submission.updated_at} parse="YYYY-MM-DD" />
      </td>
      <td className="pt-3">
        <ParsedDate date={submission.created_at} parse="YYYY-MM-DD" />
      </td>
      <td className="d-flex flex-row justify-content-center">
        <a
          type="button"
          href={`/admin/requests/${submission.id}/edit`}
          className="btn border-right btn-link"
        >
          Update
        </a>
        <button
          type="button"
          onClick={() => removeRequest(submission)}
          className="btn border-right btn-link"
        >
          Delete
        </button>

        <button
          type="button"
          onClick={() => duplicateRequest(submission)}
          className="btn btn-link"
        >
          Dupe
        </button>
      </td>
    </tr>
  );
};

export default SubmissionRow;
