import React from "react";
import Modal from "react-modal";
import useModal from "../../global/useModal";

const ModalPage = ({ children }) => {
  const [modalState, modalAction] = useModal();
  Modal.setAppElement("#modal");
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "25px"
    }
  };

  return (
    <Modal isOpen={modalState.modalOpen} style={customStyles}>
      {children}
    </Modal>
  );
};

export default ModalPage;
