import React from "react";
import CurrencyFormat from "react-currency-format";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

export default class {
  static serializeRansackParams = (obj) => {
    var str = "";
    for (var key in obj) {
      if (str != "") {
        str += "&";
      }
      str += `q[${key}]` + "=" + encodeURIComponent(obj[key]);
    }
    return str;
  };

  static serialize = (obj) => {
    var str = "";
    for (var key in obj) {
      if (str != "") {
        str += "&";
      }
      str += key + "=" + encodeURIComponent(obj[key]);
    }
    return str;
  };

  static alertConfirm(title, message, btn1, btn2) {
    return new Promise((resolve) => {
      confirmAlert({
        title: title,
        message: message,
        buttons: [
          {
            label: btn1,
            onClick: () => resolve(true),
          },
          {
            label: btn2,
            onClick: () => resolve(false),
          },
        ],
      });
    });
  }

  static formatCurrency(value) {
    return (
      <CurrencyFormat
        value={value}
        displayType={"text"}
        thousandSeparator={true}
        prefix={"$"}
      />
    );
  }
}
