import React, { Fragment } from "react";

const FieldErrorMessage = ({ error, touched }) => {
  if (!error || !touched) return null;
  return (
    <Fragment>
      <div className="errorText text-danger">{error}</div>
    </Fragment>
  );
};

export default FieldErrorMessage;
