import React, { Fragment } from "react";
import NumberOfApprovedRow from "./NumberOfApprovedRow";

const NumberOfApprovedTables = ({ title, data }) => {
  return (
    <div className="table-container mt-2">
      {title && <h2 className="text-center">{title}</h2>}
      <table className="table">
        <thead className="thead-light">
          <tr>
            <th scope="col">Year</th>
            <th scope="col">January</th>
            <th scope="col">February</th>
            <th scope="col">March</th>
            <th scope="col">April</th>
            <th scope="col">May</th>
            <th scope="col">June</th>
            <th scope="col">July</th>
            <th scope="col">August</th>
            <th scope="col">September</th>
            <th scope="col">October</th>
            <th scope="col">November</th>
            <th scope="col">December</th>
            <th scope="col">Total</th>
          </tr>
        </thead>
        <tbody>
          {data.length > 0 &&
            data.map((dat, key) => {
              return Object.keys(dat).map(year => (
                <NumberOfApprovedRow {...{ key, year }} data={dat[year]} />
              ));
            })}
        </tbody>
      </table>
    </div>
  );
};

export default NumberOfApprovedTables;
