import React, { Fragment, useEffect } from "react";
import BudgetMainRow from "./BudgetMainRow";
import BudgetRequestRow from "./BudgetRequestRow";
import useBudget from "../../../../global/useBudget";
import useModal from "../../../../global/useModal";

const BudgetTable = ({ title }) => {
  const [budgetState, budgetAction] = useBudget();
  const [modalState, modalAction] = useModal();

  const openModal = month => {
    let budget =
      budgetState.budget[budgetState.year.value].months[month].budget;
    budgetAction.setGlobalState({
      selectedBudget: budget ? budget : null,
      modalType: "updateBudget",
      month: month
    });
    modalAction.setModal(true);
  };

  return (
    <div className="table-container mt-2">
      {title && <h2 className="text-center">{title}</h2>}
      <table className="budget-table table">
        <thead className="thead-light">
          <tr>
            <th scope="col"></th>
            <th scope="col">JAN</th>
            <th scope="col">FEB</th>
            <th scope="col">MAR</th>
            <th scope="col">APR</th>
            <th scope="col">MAY</th>
            <th scope="col">JUN</th>
            <th scope="col">JUL</th>
            <th scope="col">AUG</th>
            <th scope="col">SEP</th>
            <th scope="col">OCT</th>
            <th scope="col">NOV</th>
            <th scope="col">DEC</th>
            <th scope="col">TOTAL</th>
          </tr>
        </thead>
        <tbody>
          {budgetState.budget && budgetState.year && (
            <Fragment>
              <BudgetMainRow
                title="Actual"
                data={budgetState.budget[budgetState.year.value]}
                type="total_event_amount_request"
                totalType="event_amount_request"
              />
              <BudgetMainRow
                title="Budget"
                data={budgetState.budget[budgetState.year.value]}
                type="budget"
                totalType="budget"
                onClick={openModal}
              />
              <BudgetMainRow
                title="Difference"
                data={budgetState.budget[budgetState.year.value]}
                type="calculated_difference"
                totalType="calculated_difference"
              />
              <BudgetRequestRow
                data={budgetState.budget[budgetState.year.value]}
              />
            </Fragment>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default BudgetTable;
