import React from "react";
import useModal from "../../../../global/useModal";
import useBudget from "../../../../global/useBudget";
import Utilities from "../../../../services/Utilities";

const BudgetRequestData = ({ data }) => {
  const [modalState, modalAction] = useModal();
  const [budgetState, budgetAction] = useBudget();

  const openModal = () => {
    budgetAction.setGlobalState({
      selectedRequest: data,
      modalType: "requestDetail"
    });
    modalAction.setModal(true);
  };

  const getColor = id => {
    let color = "";
    if (budgetState.legends) {
      let res = budgetState.legends.find(legend => {
        return legend.id == id;
      });
      if (res) {
        color = res.color;
      }
    }
    return color;
  };

  return (
    <div
      className="request-data py-1 px-2"
      style={{
        backgroundColor: data.request_legend_id
          ? getColor(data.request_legend_id)
          : ""
      }}
      onClick={openModal}
    >
      {Utilities.formatCurrency(data.event_amount_request)}
    </div>
  );
};

export default BudgetRequestData;
