import React from "react";

const GoBack = ({ label = "< Go Back", cb = () => {} }) => {
  return (
    <div className="w-100">
      <button type="button" className="btn btn-dark mb-4" onClick={cb}>
        {label}
      </button>
    </div>
  );
};

export default GoBack;
