import React from "react";
import { useField, useFormikContext } from "formik";

const FileUploadField = ({ ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);
  return (
    <input
      {...field}
      {...props}
      onChange={event => {
        let tmpfile = [];
        let eventFiles = event.currentTarget.files;
        if (event.currentTarget && eventFiles.length > 0) {
          for (let i = 0; i < eventFiles.length; i++) {
            tmpfile.push(event.currentTarget.files[i]);
          }
          setFieldValue("file", tmpfile);
        }
      }}
    />
  );
};

export default FileUploadField;
