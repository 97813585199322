import React from "react";

const NumberOfApprovedRow = ({ year, data }) => {
  return (
    <tr>
      <td className="pt-3 text-center">{year}</td>
      {Object.keys(data.months).map((month, key) => (
        <td className="pt-3 text-center" {...{ key }}>
          {data.months[month]}
        </td>
      ))}
      <td className="pt-3 text-center">{data.total}</td>
    </tr>
  );
};

export default NumberOfApprovedRow;
