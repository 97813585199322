import React, { Fragment, useState } from "react";
import SubmissionRow from "./SubmissionRow";

const SubmissionTable = ({
  submissions,
  hasMore,
  pageInfo,
  getForms,
  removeRequest,
  duplicateRequest,
  filters,
  applyFilter,
}) => {
  const SortBtn = ({ val }) => {
    let icon = "fa-sort";
    if (val == "ASC") {
      icon = "fa-sort-asc";
    } else if (val == "DESC") {
      icon = "fa-sort-desc";
    }
    return (
      <span>
        <i className={`fa ${icon} pl-2`}></i>
      </span>
    );
  };

  return (
    <Fragment>
      <a className="btn btn-dark mb-2 float-right" href="/admin/requests/new">
        Create Request
      </a>
      <table className="table-submission table">
        <thead className="thead-light">
          <tr className="text-center">
            <th scope="col" onClick={() => applyFilter("org_name")}>
              Company
              <SortBtn val={filters.org_name} />
            </th>
            <th scope="col" onClick={() => applyFilter("created_at")}>
              Sumitted
              <SortBtn val={filters.created_at} />
            </th>
            <th scope="col" onClick={() => applyFilter("event_amount_request")}>
              Cost
              <SortBtn val={filters.event_amount_request} />
            </th>
            <th scope="col" onClick={() => applyFilter("status")}>
              Status
              <SortBtn val={filters.status} />
            </th>
            <th scope="col" onClick={() => applyFilter("updated_at")}>
              Updated
              <SortBtn val={filters.updated_at} />
            </th>
            <th scope="col" onClick={() => applyFilter("created_at")}>
              Created
              <SortBtn val={filters.created_at} />
            </th>
            <th scope="col" className="text-center">
              Manage
            </th>
          </tr>
        </thead>
        <tbody>
          {submissions.map((submission, key) => (
            <SubmissionRow
              key={key}
              submission={submission}
              removeRequest={removeRequest}
              duplicateRequest={duplicateRequest}
            />
          ))}
        </tbody>
      </table>

      {pageInfo.page > 0 && hasMore && (
        <div className="w-100 text-center">
          <button
            type="button"
            onClick={getForms}
            className="btn btn-outline-dark mx-2 w-50"
            disabled={pageInfo.hasMoreLoading}
          >
            {!pageInfo.hasMoreLoading ? "Load More" : "Loading..."}
          </button>
        </div>
      )}

      {submissions.length < 1 && (
        <h3 className="text-center">No items found.</h3>
      )}
    </Fragment>
  );
};

export default SubmissionTable;
