import responsive from "../../themes/Responsive";

const searchFormTemplate = {
  budgetInformation: {
    label: null,
    fields: [
      {
        name: "amount",
        type: "text",
        placeholder: "Enter Amount",
        responsive: `${responsive.halfFormWidth} offset-3`
      }
    ]
  }
};

export default searchFormTemplate;
