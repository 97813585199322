import React from "react";
import Moment from "react-moment";

const ParseDate = props => {
  const { date, format = "short", parse } = props;
  var type = "Do MMMM YYYY, h:mm:ss a";
  switch (format) {
    case "short":
      type = "MMM Do YYYY";
      break;
    case "long":
      type = "MMMM Do YYYY";
      break;
  }
  return (
    <Moment format={type} parse={parse}>
      {date}
    </Moment>
  );
};

export default ParseDate;
